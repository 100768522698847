import type { FC, PropsWithChildren } from 'react';

/**
 * Used for only rendering components for logged in YourSurprise employees
 */
const InternalUserGuard: FC<PropsWithChildren<{ email: string | undefined }>> = ({ children, email }) => {
    if (!email?.endsWith('@yoursurprise.com')) {
        return null;
    }

    return children;
};

export default InternalUserGuard;
